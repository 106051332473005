:root {
  --main-color1: hsl(213, 44%, 19%);
  @include defineColorHSL(--main-color1, 212, 38%, 27%);
  --main-color: hsl(212, 38%, 27%);
  @include defineColorHSL(--main-color, 212, 38%, 27%);
  --main-color-sub1: hsl(212, 20%, 37%);
  --main-color-sub2: hsl(210, 23%, 78%);
  --main-color-sub3: hsl(240, 22%, 95%);

  --main-color-sidebar: hsl(0, 0%, 96%);
  --main-color-highlight: hsl(213, 33%, 37%);
  --main-color-close: hsl(0, 49%, 51%);
  --main-color-edit: hsl(123, 38%, 27%);
  @include defineColorHSL(--main-color-edit, 123, 38%, 27%);
  --main-color-cancel: hsl(0, 46%, 43%);
  --main-color-option: hsl(210, 5%, 92%);
  --main-color-delete: hsl(0, 59%, 41%);
  @include defineColorHSL(--main-color-delete, 0, 59%, 41%);
  --main-color-in-process: hsl(40, 70%, 50%);
  --main-color-disabled: hsl(0, 0%, 62%);
  --main-color-transparent: hsla(0, 0%, 62%, 0.432);
  --main-color-not-set: hsl(0, 0%, 80%);
  --main-color-regular: hsl(0, 0%, 100%);
  @include defineColorHSL(--main-color-regular, 0, 0%, 100%);

  --white-color: hsl(0, 0%, 100%);
  @include defineColorHSL(--white-color, 0, 0%, 100%);
  --black-color: hsl(0, 0%, 0%);
  @include defineColorHSL(--black-color, 0, 0%, 0%);

  --main-color-new-clash: hsl(0, 100%, 50%);
  --main-color-active-clash: hsl(36, 100%, 50%);
  --main-color-reviewed-clash: hsl(192, 100%, 50%);
  --main-color-approved-clash: hsl(120, 100%, 50%);
  --main-color-resolved-clash: hsl(60, 100%, 50%);
}

%save {
  @include colorElement(var(--white-color), var(--main-color));
  &:focus {
    @include colorElement(var(--white-color), var(--main-color));
  }
  &:hover {
    @include colorHoverElement(var(--white-color), var(--main-color), true);
  }
  .icon {
    background-color: var(--white-color);
  }
}
%default {
  @include colorElement(var(--black-color), var(--white-color));
  &:focus {
    @include colorElement(var(--black-color), var(--white-color));
  }
  &:hover {
    @include colorHoverElement(var(--black-color), var(--white-color));
  }
  .icon {
    background-color: var(--black-color);
  }
}
%edit {
  @include colorElement(var(--white-color), var(--main-color-edit));
  &:focus {
    @include colorElement(var(--white-color), var(--main-color-edit));
  }
  &:hover {
    @include colorHoverElement(var(--white-color), var(--main-color-edit));
  }
}

%not-set {
  @include colorElement(var(--black-color), var(--main-color-not-set));
  &:focus {
    @include colorElement(var(--black-color), var(--main-color-not-set));
  }
  &:hover {
    @include colorHoverElement(var(--black-color), var(--main-color-not-set));
  }
  .icon {
    background-color: var(--black-color) !important;
  }
}

%regular {
  @include colorElement(var(--black-color), var(--main-color-regular));
  &:focus {
    @include colorElement(var(--black-color), var(--main-color-regular));
  }
  &:hover {
    @include colorHoverElement(var(--black-color), var(--main-color-regular));
  }
  .icon {
    background-color: var(--black-color) !important;
  }
}

%cancel {
  @include colorElement(var(--white-color), var(--main-color-cancel));
  &:focus {
    @include colorElement(var(--white-color), var(--main-color-cancel));
  }
  &:hover {
    @include colorHoverElement(var(--white-color), (var(--main-color-cancel)));
  }
}

%option {
  @include colorElement(var(--black-color), var(--main-color-option));
  &:focus {
    @include colorElement(var(--black-color), var(--main-color-option));
  }
  &:hover {
    @include colorHoverElement(var(--black-color), var(--main-color-option));
  }
}

%delete {
  @include colorElement(var(--white-color), var(--main-color-delete));
  &:focus {
    @include colorElement(var(--white-color), var(--main-color-delete));
  }
  &:hover {
    @include colorHoverElement(var(--white-color), var(--main-color-delete));
  }
}
%ghost {
  @include colorElement(var(--black-color), transparent);
  &:focus {
    @include colorElement(var(--black-color), transparent);
  }
  &:hover {
    @extend %hover;
  }
  .icon {
    background-color: var(--black-color);
  }
}
%active {
  @include colorElement(var(--main-color), var(--white-color));
  &:focus {
    @include colorElement(var(--main-color), var(--white-color));
  }
  border: 1px solid var(--black-color) !important;
  .icon {
    background-color: var(--black-color);
  }
  &.ghost {
    background-color: var(--main-color-highlight);
    border: none !important;
    .icon {
      background-color: var(--white-color);
    }
  }
}
%hover {
  @include colorElement(var(--black-color), var(--white-color));
  .icon {
    background-color: var(--main-color);
  }
}
%disabled {
  @include colorElement(var(--black-color), var(--main-color-disabled), true);
  pointer-events: none;
  opacity: 0.8;
  div {
    opacity: 0.8;
  }
}
%circle {
  border-radius: 50%;
}
%sssmall {
  @include textSize(12);
  .icon {
    @include sizeIcon(12);
  }
}
%ssmall {
  @include textSize(14);
  .icon {
    @include sizeIcon(13);
  }
}
%small {
  @include textSize(16);
  .icon {
    @include sizeIcon(16);
  }
}
%medium {
  @include textSize(18);
  .icon {
    @include sizeIcon(18);
  }
  // span {
  //     @include sizeIcon(12);
  // }
}
%large {
  @include textSize(20);
  .icon {
    @include sizeIcon(20);
  }
}
%block {
  width: 100%;
}
%icon {
  padding: 5px;
  background-color: var(--white-color);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

%align_all_center {
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-content: center;
}
%align_center {
  display: flex !important;
  justify-content: center;
  align-content: center;
}
