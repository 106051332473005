.idd-form-auth {
  box-shadow: 0px 0px 5px 0px;
  // border: 1px solid var(--main-color);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 200px;
  width: 300px;
  max-width: 400px;
  background-color: var(--white-color);
  border-radius: 5px;
  overflow: hidden;
  .header {
    padding: 5px;
    background-color: var(--main-color-sub);
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .body {
    padding: 15px;
    width: 100%;
  }
}
