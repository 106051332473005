@mixin colorElement($color, $bgColor, $important: false) {
  @if ($important) {
    color: $color !important;
    background-color: $bgColor !important;
  } @else {
    color: $color;
    background-color: $bgColor;
  }
}
@mixin colorHoverElement($color, $bgColor, $important: false) {
  @if ($important) {
    color: $color !important;
    background-color: lightness($bgColor, 1.5) !important;
  } @else {
    color: $color !important;
    background-color: lightness($bgColor, 1.5);
  }
}
@mixin textSize($height) {
  font-size: $height * 1px;
  height: powerOfTwo($height) * 1px;
  width: powerOfTwo($height) * 1px;
}
@mixin sizeIcon($dim) {
  width: powerOfTwo($dim) * 1px;
  height: powerOfTwo($dim) * 1px;
  -webkit-mask-size: iconSize($dim) * 1px;
}
@function powerOfTwo($height) {
  @return $height * 2;
}
@function iconSize($height) {
  @return ($height * 2)-12;
}
@mixin paddingTopBottom($dim) {
  padding-top: $dim * 1px;
  padding-bottom: $dim * 1px;
}

@function returnColor($color) {
  @return $color;
}

@function lightness($color, $lightnessMultiplier) {
  $color: str-replace($color, 'var(');
  $color: str-replace($color, ')');
  $color-h: var(#{$color + '-h'});
  $color-s: var(#{$color + '-s'});
  $color-l: var(#{$color + '-l'});
  @return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightnessMultiplier}));
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}
@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
  #{$color}: unquote('hsl(#{$hue}, #{$saturation}, #{$lightness})');
  #{$color}-h: #{$hue};
  #{$color}-s: #{$saturation};
  #{$color}-l: #{$lightness};
}
