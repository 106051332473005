.ag-theme-alpine {
  .ag-row {
    font-size: 12px !important;
    &.ag-row-group {
      background-color: #dee3ea;
    }
  }
  .ag-header-group-cell {
    .ag-header-group-cell-label {
      @apply text-white #{!important};
    }
  }
  .ag-cell {
    @apply border border-b-[1px] border-l-[1px] border-solid border-gray-300;
  }
  .ag-header-cell {
    @apply bg-primary-600 hover:bg-primary-600 dark:bg-primary-700 dark:hover:bg-primary-700;
    &:hover {
      @apply bg-primary-700 dark:bg-primary-700 #{!important};
    }
    .ag-header-cell-label {
      @apply text-white #{!important};
    }
    .ag-header-icon {
      @apply text-white #{!important};
    }
  }
  .ag-header-group-cell {
    @extend .ag-header-cell;
  }
  .ag-column-drop {
    @apply bg-primary-600 dark:bg-primary-700;
  }
}

.gantt_task_progress {
  text-align: left;
  padding-left: 10px;
  box-sizing: border-box;
  color: white;
  font-weight: bold;
}

.gantt_task_line.gantt_dependent_task {
  background-color: #65c16f;
  border: 1px solid #3c9445;
}

.gantt_task_line.gantt_dependent_task .gantt_task_progress {
  background-color: #46ad51;
}

.hide_project_progress_drag .gantt_task_progress_drag {
  visibility: hidden;
}

.dhtmlxMenu_dhx_terrace_SubLevelArea_Polygon {
  z-index: 10000000 !important;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.idd-custom-antd {
  &.ant-btn-dangerous {
    background: var(--main-color-delete);
  }
  .ant-tree-node-selected {
    background-color: var(--main-color) !important;
    color: var(--white-color);
  }
}

/* disable all borders */
// --ag-borders: none;
/* then add back a border between rows */
// --ag-borders-row: dashed 1px black;
// --ag-row-border-color: rgb(240, 240, 240);
// .ag-row.ag-row-level-0.ag-row-group {
//   background-color: #bfc9d7;
//   font-weight: bold;
// }
// .ag-row.ag-row-level-1.ag-row-group {
//   background-color: #dee3ea;
// }
// .ag-row.ag-row-level-2.ag-row-group {
//   background-color: #ebeff3;
// }
