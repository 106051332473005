.idd-custom {
  &.no-border-radius {
    border-radius: 0px !important;
  }
  &.default {
    @extend %default;
  }
  &.save {
    @extend %save;
  }
  &.edit {
    @extend %edit;
  }
  &.delete {
    @extend %delete;
  }
  &.sssmall {
    @extend %sssmall;
  }
  &.ssmall {
    @extend %ssmall;
  }
  &.small {
    @extend %small;
  }
  &.medium {
    @extend %medium;
  }
  &.large {
    @extend %large;
  }
  &:disabled {
    @extend %disabled;
  }
  &.block {
    @extend %block;
  }
  &.active {
    @extend %active;
  }
  &.circle {
    @extend %circle;
  }
  &.cancel {
    @extend %cancel;
  }
  &.not-set {
    @extend %not-set;
  }
  &.regular {
    @extend %regular;
  }
}
.idd-custom-button {
  @extend .idd-custom;
  @extend %align_all_center !optional;
  border: 1px solid var(--black-color) !important;
  cursor: pointer;
}

.idd-icon {
  &.sssmall {
    @extend %sssmall;
  }
  &.ssmall {
    @extend %ssmall;
  }
  &.small {
    @extend %small;
  }
  &.medium {
    @extend %medium;
  }
  &.large {
    @extend %large;
  }
  .icon {
    padding: 5px;
    background-color: var(--black-color);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    &.loading {
      -webkit-mask-image: url('https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/24/000000/external-Spin-arrows-tanah-basah-basic-outline-tanah-basah.png') !important;
      animation: rotation 2s infinite linear;
      pointer-events: none;
    }
  }
}
.idd-icon-button {
  @extend .idd-custom-button;
  @extend %medium;
  border-radius: 5px;
  border: none !important;
  padding: 5px;
  gap: 5px;
  pointer-events: unset;

  .icon {
    @extend %icon;
    &.loading {
      -webkit-mask-image: url('https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/24/000000/external-Spin-arrows-tanah-basah-basic-outline-tanah-basah.png') !important;
      animation: rotation 2s infinite linear;
      pointer-events: none;
    }
  }
  &.ghost {
    @extend %ghost;
  }

  &.border {
    border: 1px solid var(--black-color) !important;
  }
}
.idd-icon-text-button {
  @extend .idd-icon-button;
  width: 100% !important;
  span {
    width: auto;
    height: auto;
    padding: 5px;
    // font-size: 12px;
  }
}

.idd-sidebar {
  @extend %align_center;
  @include paddingTopBottom(5);
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start !important;
  height: 100%;
  background-color: var(--main-color);
}
.idd-sidebar-dms {
  @extend .idd-sidebar;
  width: 55px;
  padding: 10px 2px;
  gap: 5px;
  justify-content: space-between !important;
}

.idd-sidebar-five-d {
  @extend .idd-sidebar;
  background-color: var(--white-color);
  padding: 10px 2px;
  gap: 5px;
  justify-content: space-between !important;
}

.idd-divider {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 1px;
  background-color: var(--main-color-disabled);
}

.idd-list {
  // flex-direction: column;
  overflow: auto;
  gap: 8px;
  display: flex;
  padding: 5px;
  align-content: flex-start;
  flex-wrap: wrap;
  .issue-card {
    cursor: pointer;
    width: 100%;
    height: 125px;
    min-height: 125px;
    padding: 5px;
    border: 1px solid var(--black-color);
    border-radius: 2px;
    background-color: var(--white-color);
    overflow: auto;
    &:hover {
      box-shadow: 0px 0px 8px 2px var(--main-color);
    }
    &.disabled {
      @extend %disabled;
    }
    &.active {
      background-color: var(--main-color-active);
    }
  }
  .view-card {
    @extend .issue-card;
    width: 150px;
    min-width: 150px;
    height: 100px;
    min-height: 100px;
    overflow: hidden;
    padding: 0px;
  }
  .animation-card {
    @extend .issue-card;
    height: 80px;
    min-height: 80px;
    overflow: hidden;
    padding: 0px;
    border-radius: 5px;
    border-left: 5px solid var(--main-color);

    .content {
      padding: 5px;
      overflow: hidden;
    }
    .control {
      height: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      .anticon {
        color: var(--white-color);
      }

      .control-delete {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: var(--main-color-delete);
        width: 25px;
        &:hover {
          background-color: lightness(var(--main-color-delete), 1.5);
        }
      }
      .control-edit {
        @extend .control-delete;
        background-color: var(--main-color-edit);
        &:hover {
          background-color: lightness(var(--main-color-edit), 1.5);
        }
      }
    }
  }
  .clash-detection-card {
    @extend .animation-card;
    height: unset;
    min-height: unset;
  }
}
.idd-input {
  &.block {
    @extend %block;
  }
}

.idd-popover {
  .ant-popover-inner-content {
    padding: 5px 0px;
  }
}

.idd-nav {
  border-bottom: 1px solid black;
  height: 50px;
  position: relative;
  align-items: center;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  background-color: white;
  .layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    .sub-1 {
      height: 20px;
      display: flex;
      align-content: center;
      align-items: center;
      padding-left: 5px;
      // padding: 2px;
      width: 100%;
      background-color: var(--black-color);
      span {
        color: white;
        font-size: 10px;
      }
    }
    .sub-2 {
      padding: 0px 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      display: flex;
      align-content: center;
      align-items: center;
      // background-color: var(--main-color);
    }
    .sub-3 {
      background-color: var(--main-color);
      width: 100%;
      height: 20px;
    }
  }
}

.idd-card {
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--main-color);
  width: 250px;
  // height: 300px;
  overflow: hidden;
  .card-content {
    .cover {
      height: 150px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: var(--main-color-not-set);
    }
  }
  .card-name {
    padding: 5px 0px;
    border-top: 1px solid var(--black-color);
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    background-color: var(--main-color);
    .main {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      font-weight: bolder;
      color: var(--white-color);
    }
    .sub {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: bolder;
      color: var(--main-color-disabled);
    }
  }
  .card-action {
    display: none;
  }
  .card-footer {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    padding: 5px;
    border-top: 1px solid var(--black-color);
    background-color: var(--main-color);
    margin: 0px;
    padding: 0px;
    line-height: 0.5em;
    position: relative;
  }
  &:hover {
    .card-action {
      display: block;
    }
    .card-name {
      background-color: var(--white-color);
      .main {
        color: var(--black-color);
      }
    }

    box-shadow: 0px 0px 8px 2px var(--main-color);
    cursor: pointer;
  }
}

.card-document-name {
  @extend .card-name;
  &.not-set {
    background-color: var(--main-color-not-set) !important;
    span {
      color: var(--black-color) !important;
    }
  }
  &.in-process {
    background-color: var(--main-color-in-process) !important;
  }
  &.completed {
    background-color: var(--main-color) !important;
  }
}

.idd-modal-template {
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  margin: 15px;
  position: absolute;
  // width: 80%;
  top: 10px;

  .header {
    background-color: var(--main-color);
    color: var(--white-color);
    span {
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    padding: 0px 5px 0px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    white-space: nowrap;
  }
  .body {
    flex-direction: column;
    gap: 5px;
    // padding: 0px 15px 0px 15px;
    display: flex;
    padding: 15px 15px;
    height: calc(100% - 40px);
  }
  .footer {
    gap: 5px;
    padding: 0px 5px 0px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
}
.idd-backdrop {
  overflow: auto;
  background-color: var(--main-color-transparent);

  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
}

.idd-form {
  .column {
    flex-wrap: wrap;
    display: flex;
    // justify-content: center;
    gap: 10px;
  }
}

.idd-tabs {
  background-color: var(--main-color);
  height: 100%;
  width: 100%;
  display: flex;
  gap: 25px;
  align-content: center;
  align-items: center;
  padding: 0px 25px;
  .tab {
    span {
      color: var(--white-color);
      font-size: 16px;
      font-weight: 500px;
    }
    &.active {
      color: var(--main-color);
      border-bottom: 4px solid var(--white-color);
      padding-bottom: 3px;
    }

    cursor: pointer;
  }
}

.idd-custom-tabs {
  &.cobie {
    height: 100%;
  }
  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
}

.idd-tree-node {
  .ant-tree-node-content-wrapper {
    display: flex;
    height: 35px;
    align-items: center;
    .ant-tree-title {
      width: 100%;
    }
  }
  .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.idd-breadcrumb {
  li {
    display: flex;
    align-items: center;
  }
}

.idd-text-left-line {
  padding-left: 5px;
  border-left: 5px;
  border-left-style: solid;
  &.new-clash {
    border-left-color: var(--main-color-new-clash);
  }
  &.active-clash {
    border-left-color: var(--main-color-active-clash);
  }
  &.reviewed-clash {
    border-left-color: var(--main-color-reviewed-clash);
  }
  &.approved-clash {
    border-left-color: var(--main-color-approved-clash);
  }
  &.resolved-clash {
    border-left-color: var(--main-color-resolved-clash);
  }
}

.idd-badge {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid black;
  padding: 5px;
  &.new-clash {
    background-color: var(--main-color-new-clash);
  }
  &.active-clash {
    background-color: var(--main-color-active-clash);
  }
  &.reviewed-clash {
    background-color: var(--main-color-reviewed-clash);
  }
  &.approved-clash {
    background-color: var(--main-color-approved-clash);
  }
  &.resolved-clash {
    background-color: var(--main-color-resolved-clash);
  }
}
#idd-popup-clash {
  z-index: 100001;
  .content {
    border-style: solid;
    border-width: 2px;
    &.new-clash {
      border-color: var(--main-color-new-clash);
    }
    &.active-clash {
      border-color: var(--main-color-active-clash);
    }
    &.reviewed-clash {
      border-color: var(--main-color-reviewed-clash);
    }
    &.approved-clash {
      border-color: var(--main-color-approved-clash);
    }
    &.resolved-clash {
      border-color: var(--main-color-resolved-clash);
    }
  }
}
span {
  &.new-clash {
    color: var(--main-color-new-clash);
  }
  &.active-clash {
    color: var(--main-color-active-clash);
  }
  &.reviewed-clash {
    color: var(--main-color-reviewed-clash);
  }
  &.approved-clash {
    color: var(--main-color-approved-clash);
  }
  &.resolved-clash {
    color: var(--main-color-resolved-clash);
  }
}

.idd-upload-input {
  width: 100%;
  .ant-upload {
    width: 100%;
  }
}

.idd-itme{
  display: flex;
  gap: 5px;
  align-items:center;
  white-space: nowrap;
}