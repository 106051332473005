.idd-search-input-form {
  .ant-select-selector {
    //   @apply block w-full focus:border-delete-500 focus:ring-delete-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 #{!important};
    @apply h-[46px] border-gray-300 bg-gray-50 p-[10px] #{!important};
    &:focus {
      @apply border-primary-600 #{!important};
    }
  }
  .ant-select-arrow {
    @apply translate-y-[50%] #{!important};
  }
}

.ant-input {
  @apply h-[32px] rounded #{!important};
}
