
.tag-get {
    background-color: #61affe !important;
    color: var(--white-color) !important;
}
.tag-post {
    background-color: #49cc90 !important;
    color: var(--white-color) !important;
}
.tag-put {
    background-color: #fca130  !important;
    color: var(--white-color) !important;
}
.tag-delete {
    background-color: #f93e3e!important;
    color: var(--white-color) !important;
}
