.idd-viewer-layout {
    width: 200px;
    height: 100%;
    background-color: white;
    z-index: 101;
    position: relative;
}

.idd-viewer-drawer {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {
        span {
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        padding: 10px 5px 0px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        white-space: nowrap;
    }
    .body {
        flex-direction: column;
        gap: 5px;
        padding: 0px 5px 0px 5px;
        display: flex;

        height: calc(100% - 40px);
    }
    .footer {
        padding: 0px 5px 0px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
    }
}

.idd-button-viewer {
    // width: 50px;
    background-color: unset;
    height: 70px;
    align-items: center;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-content: center;
    gap: 5px;
    cursor: pointer;
    border: none;
    .icon {
        width: 50px;
        height: 50px;
        padding: 5px;
        -webkit-mask-size: 30px;
        background-color: var(--black-color);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        &:hover {
            background-color: var(--main-color);
        }
    }
    &:hover {
        border: 1px solid var(--main-color);
    }
    &.active {
        @extend %active;
        // background-color: var(--main-color-highlight);
        // border: 1px solid var(--main-color);
        .icon {
            background-color: var(--main-color-highlight);
        }
        span {
            color: var(--main-color-highlight);
        }
    }
    &:disabled {
        @extend %disabled;
    }
}

#issue-image {
    display: flex;
    justify-content: center;
    background-size: 400px;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    div {
        display: none !important;
    }
    &:hover {
        div {
            display: block !important;
        }
    }
}

.idd-viewer-card {
    border: 1px solid black;
    border-radius: 5px;
    .header {
        padding: 10px;
        color: white;
        background-color: var(--main-color);
        display: flex;
        justify-content: space-between;
    }
    .body {
        padding: 5px;
        display: flex;
        gap: 5px;
        flex-direction: column;
        &.hide {
            display: none;
        }
    }
}

.idd-animation-item {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    border: 1px solid black;
    align-content: center;
    padding: 2px 5px 2px 5px;
    align-items: center;
    border-radius: 5px;
    border-left: 5px solid var(--main-color);
    &.active{
        border-left: 10px solid var(--main-color-edit) !important;
    }
}

.vis-item {
    &.CastingCompleted {
      color: #1890ff;
      background-color: #e6f7ff;
      border-color: #91d5ff;
      text-align: center;
    }
    &.DeliveredtoFit-outYard {
      color: #52c41a;
      background-color: #f6ffed;
      border-color: #b7eb8f;
      text-align: center;
    }
    &.FitOutCompleted {
      color: #fadb14;
      background-color: #feffe6;
      border-color: #fffb8f;
      text-align: center;
    }
    &.DeliveredtoSite {
      color: #fa8c16;
      background-color: #fff7e6;
      border-color: #ffd591;
      text-align: center;
    }
    &.InstalledonSite {
      color: #722ed1;
      background-color: #f9f0ff;
      border-color: #d3adf7;
      text-align: center;
    }
  }
  
  
.vis-custom-time.custom-timeline-progress-ppvc {
    background-color: rgb(5, 145, 0);
  }
