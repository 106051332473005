// @layer tailwind-base, antd;

// @layer tailwind-base {
//   @import 'tailwindcss/base';
// }
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './animation/index.scss';
@import './functions/index.scss';
@import './custom/index.scss';
@import './constant/index.scss';
@import './General.scss';
@import './Login.Page.scss';
@import './Viewer.Page.scss';
@import './ProjectManagement.Page.scss';
@import './custom//antd//index.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

#notTranslate {
  background-color: var(--main-color-disabled);
  color: var(--white-color);
}
#inProcess {
  background-color: var(--main-color-in-process);
  color: var(--white-color);
}
#completed {
  background-color: var(--main-color-edit);
  color: var(--white-color);
}

.dock-panel {
  background: #eef4f7 !important;
}

#zmmtg-root {
  display: none;
  min-width: 0 !important;
}
